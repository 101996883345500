<template>
  <hr class="separator " style="margin-top: 1px"/>

  <div>
    <div class="custom-text">
      <span class="user-manage"> {{ $t('ProductManage.ConfigManage.productManage') }} &nbsp;/&nbsp;</span>
      {{ $t('ProductManage.ConfigManage.configManage') }}
    </div>
  </div>

  <div class="all-tab">
    <el-tabs v-model="activeTab">
      <el-tab-pane :label="$t('ProductManage.ConfigManage.motor')" name="motor">
        <el-tabs v-model="motorTab" type="card" @tab-click="motorTabClick">
          <el-tab-pane :label="$t('ProductManage.ConfigManage.firmware')" name="motorFirmware">
            <FirmwarePage v-if="motorTab === 'motorFirmware'"/>
          </el-tab-pane>
          <el-tab-pane :label="$t('ProductManage.ConfigManage.parameterDocument')" name="motorParameterFile">
            <ParameterFilePage v-if="motorTab === 'motorParameterFile'"/>
          </el-tab-pane>
          <el-tab-pane :label="$t('ProductManage.ConfigManage.itemsUnderTesting')" name="motorTestItem">
            <TestItemPage v-if="motorTab === 'motorTestItem'"/>
          </el-tab-pane>
          <el-tab-pane :label="$t('ProductManage.ConfigManage.fault')" name="motorFault">
            <FaultPage v-if="motorTab === 'motorFault'"/>
          </el-tab-pane>
        </el-tabs>
        <!-- 电机配置内容 -->
        <!-- 你可以在这里放电机相关的配置内容 -->
      </el-tab-pane>

      <el-tab-pane :label="$t('ProductManage.ConfigManage.battery')" name="battery">
        <!-- 电池配置内容 -->
        <!-- 你可以在这里放电池相关的配置内容 -->
        <el-tabs v-model="batteryTab" type="card">
          <el-tab-pane :label="$t('ProductManage.ConfigManage.itemsUnderTesting')" name="batteryTestItem">
            <BatteryTestItemComponent v-if="batteryTab === 'batteryTestItem'"/>
          </el-tab-pane>
          <el-tab-pane :label="$t('ProductManage.ConfigManage.fault')" name="batteryFault">
            <BatteryFaultComponent v-if="batteryTab === 'batteryFault'"/>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>

      <el-tab-pane :label="$t('ProductManage.ConfigManage.dashboard')" name="dashboard">
        <!-- 仪表盘配置内容 -->
        <!-- 你可以在这里放仪表盘相关的配置内容 -->
        <el-tabs v-model="dashboardTab" type="card">
          <el-tab-pane :label="$t('ProductManage.ConfigManage.firmware')" name="dashboardFirmware">
            <DashboardFirmwareComponent v-if="dashboardTab === 'dashboardFirmware'"/>
          </el-tab-pane>
          <el-tab-pane :label="$t('ProductManage.ConfigManage.itemsUnderTesting')" name="dashboardTestItem">
            <DashboardTestItemComponent v-if="dashboardTab === 'dashboardTestItem'"/>
          </el-tab-pane>
          <el-tab-pane :label="$t('ProductManage.ConfigManage.fault')" name="dashboardFault">
            <DashboardFaultComponent v-if="dashboardTab === 'dashboardFault'"/>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
//电机的组件
import FirmwarePage from './components/Motor/FirmwarePage.vue';
import ParameterFilePage from './components/Motor/ParameterFilePage.vue';
import TestItemPage from './components/Motor/TestItemPage.vue';
import FaultPage from './components/Motor/FaultPage.vue';
//电池的组件
import BatteryFaultComponent from './components/Battery/FaultPage.vue';
import BatteryTestItemComponent from './components/Battery/TestItemPage.vue';
//仪表盘的组件
import DashboardFirmwareComponent from './components/DashBoard/FirmwarePage.vue';
import DashboardTestItemComponent from './components/DashBoard/TestItemPage.vue';
import DashboardFaultComponent from './components/DashBoard/FaultPage.vue';


export default {
  data() {
    return {
      activeTab: 'motor', // 默认显示电机选项
      motorTab: 'motorFirmware',
      batteryTab: 'batteryTestItem',
      dashboardTab: 'dashboardFirmware',
    };
  },
  components: {
    FirmwarePage,
    ParameterFilePage,
    TestItemPage,
    FaultPage,
    //电池组件
    BatteryFaultComponent,
    BatteryTestItemComponent,
    //仪表盘的组件
    DashboardFirmwareComponent,
    DashboardTestItemComponent,
    DashboardFaultComponent,
  },
  methods: {}
};
</script>
<style scoped>
@import "@/assets/css/publicStyle.css";

.all-tab {
  margin-left: 16px;
  margin-top: -5px;
  color: rgb(0, 154, 68);
}
</style>
